/* eslint-disable default-case */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import { loginUser, signUpUser, logoutUser } from './actions';

const initialState = {
  loading: false,
  error: null,
  isLoggedIn: false,
  token: null,
};

const reducer = produce((draft, { type, payload }) => {
  switch (type) {
    case loginUser.TRIGGER:
    case signUpUser.TRIGGER:
    case logoutUser.TRIGGER:
      draft.loading = true;
      draft.error = null;
      break;
    case loginUser.SUCCESS:
    case signUpUser.SUCCESS:
      draft.token = payload;
      draft.isLoggedIn = true;
      break;
    case logoutUser.SUCCESS:
      draft.token = null;
      draft.isLoggedIn = false;
      break;
    case loginUser.FAILURE:
    case signUpUser.FAILURE:
    case logoutUser.FAILURE:
      draft.error = payload;
      draft.token = null;
      break;
    case loginUser.FULFILL:
    case signUpUser.FULFILL:
    case logoutUser.FULFILL:
      draft.loading = false;
      break;
  }
}, initialState);

export default reducer;
