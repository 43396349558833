import { call, put, takeLatest } from 'redux-saga/effects';
import SDK from 'utils/sdk';
import { listMeClients, getClient, listAgents } from './actions';

export function* listMeClientsSaga() {
  try {
    yield put(listMeClients.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.clients, 'getClients']);
    yield put(listMeClients.success(body));
  } catch (error) {
    yield put(listMeClients.failure(error));
  } finally {
    yield put(listMeClients.fulfill());
  }
}

export function* getClientSaga({ payload: { id } }) {
  try {
    yield put(getClient.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.clients, 'getClient'], { id }, {});
    yield put(getClient.success(body));
  } catch (error) {
    yield put(getClient.failure(error));
  } finally {
    yield put(getClient.fulfill());
  }
}

export function* listClientAgentsSaga({ payload: { id } }) {
  try {
    yield put(listAgents.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.clients, 'getAgents'], { id });
    yield put(listAgents.success(body));
  } catch (error) {
    yield put(listAgents.failure(error));
  } finally {
    yield put(listAgents.fulfill());
  }
}

export default function* clientsWatcher() {
  yield takeLatest(listMeClients.TRIGGER, listMeClientsSaga);
  yield takeLatest(getClient.TRIGGER, getClientSaga);
  yield takeLatest(listAgents.TRIGGER, listClientAgentsSaga);
}
