import { createRoutine } from 'redux-saga-routines';
import {
  LOGIN_RINGCENTRAL,
  CHECK_LOGGED_IN,
  LOGOUT_RINGCENTRAL,
  WEBSOCKET_RINGCENTRAL,
  EXTENSION_RINGCENTRAL,
  OUTBOUND_CALL,
  CALL_LEAD,
  SET_SESSION,
  ANSWER_INCOMING_CALL,
  REJECT_INCOMING_CALL,
  GET_PRESENCE,
  SET_CALLING_MODE,
  INCOMING_CALL,
  MUTE,
  HOLD,
  TRANSFER,
  TERMINATE,
} from './types';

export const login = createRoutine(LOGIN_RINGCENTRAL);
export const logout = createRoutine(LOGOUT_RINGCENTRAL);
export const checkLoggedInRC = createRoutine(CHECK_LOGGED_IN);
export const getPresence = createRoutine(GET_PRESENCE);
export const setCallingMode = createRoutine(SET_CALLING_MODE);
export const websocket = createRoutine(WEBSOCKET_RINGCENTRAL);
export const extension = createRoutine(EXTENSION_RINGCENTRAL);
export const outboundCall = createRoutine(OUTBOUND_CALL);
export const callLead = createRoutine(CALL_LEAD);
export const setSession = createRoutine(SET_SESSION);
export const answerIncomingCall = createRoutine(ANSWER_INCOMING_CALL);
export const rejectIncomingCall = createRoutine(REJECT_INCOMING_CALL);
export const incomingCall = createRoutine(INCOMING_CALL);

export const handleMute = createRoutine(MUTE);
export const handleHold = createRoutine(HOLD);
export const handleTransfer = createRoutine(TRANSFER);
export const handleTerminate = createRoutine(TERMINATE);
