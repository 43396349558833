import { createSelector } from 'reselect';

export const getAuth = (state) => state.auth;

export const getAuthIsLoggedIn = createSelector(
  getAuth,
  (auth) => auth.isLoggedIn
);

export const isAuthLoading = createSelector(getAuth, (auth) => auth.loading);
export const getAuthToken = createSelector(getAuth, (auth) => auth.token);
export const getAuthError = createSelector(getAuth, (auth) => auth.error);
