import React from 'react';
import classnames from 'classnames';

const ScreenLoading = ({ isInitial = true }) => (
  <div
    className={classnames(
      'hot-ui--loader-container d-flex justify-content-center align-items-center',
      { main: isInitial }
    )}
  >
    <div className="hot-ui--loader" />
  </div>
);

export default ScreenLoading;
