export const LIST_LEADS = 'LIST_LEADS';
export const CREATE_LEAD = 'CREATE_LEAD';
export const UPDATE_LEAD = 'UPDATE_LEAD';
export const RETRIEVE_LEAD = 'RETRIEVE_LEAD';
export const SET_FILTER = 'SET_FILTER';
export const CREATE_NOTE = 'CREATE_NOTE';
export const NEXT_LEAD = 'NEXT_LEAD';
export const SEARCH_LEAD = 'SEARCH_LEAD';
export const RESCHEDULE = 'RESCHEDULE';
export const CHANGE_GROUP = 'CHANGE_GROUP';
export const NEXT_GROUP = 'NEXT_GROUP';
export const VOICEMAIL = 'VOICEMAIL';
export const START_CALL = 'START_CALL';
export const CLEAR_LEAD = 'CLEAR_LEAD';
