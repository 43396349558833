/* eslint-disable default-case */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import { listGroups, setGroupDropdown, setGroup } from './actions';

const initialState = {
  error: null,
  items: [],
  dropdown: [],
  loading: false,
  current: null,
};

const reducer = produce((draft, { type, payload }) => {
  switch (type) {
    case listGroups.TRIGGER:
      draft.loading = true;
      draft.error = null;
      break;
    case listGroups.SUCCESS:
      draft.items = payload;
      break;
    case setGroup.toString():
      draft.current = payload;
      break;
    case setGroupDropdown.toString():
      draft.dropdown = payload;
      break;
    case listGroups.FAILURE:
      draft.error = payload;
      break;
    case listGroups.FULFILL:
      draft.loading = false;
      break;
  }
}, initialState);

export default reducer;
