/* eslint-disable default-case */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import { retrieveMe, updateMe } from './actions';

const initialState = {
  error: null,
  item: null,
  loading: false,
};

const reducer = produce((draft, { type, payload }) => {
  switch (type) {
    case retrieveMe.TRIGGER:
    case updateMe.TRIGGER:
      draft.loading = true;
      draft.error = null;
      break;
    case retrieveMe.SUCCESS:
      draft.item = payload;
      break;
    case retrieveMe.FAILURE:
    case updateMe.FAILURE:
      draft.error = payload;
      break;
    case retrieveMe.FULFILL:
    case updateMe.FULFILL:
      draft.loading = false;
      break;
  }
}, initialState);

export default reducer;
