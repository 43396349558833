import 'assets/styles/style.scss';
import 'utils/sentry';

import React from 'react';
import { render, hydrate } from 'react-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faClipboardCheck,
  faArrowLeft,
  faGraduationCap,
  faPhone,
  faPhoneAlt,
  faPhoneSlash,
  faCalendar,
  faSortUp,
  faSortDown,
  faSort,
  faCaretUp,
  faCaretDown,
  faUserPlus,
  faSignal,
} from '@fortawesome/free-solid-svg-icons';
import Loadable from 'react-loadable';
import App from './app';

library.add(
  faClipboardCheck,
  faArrowLeft,
  faGraduationCap,
  faPhone,
  faPhoneAlt,
  faPhoneSlash,
  faCalendar,
  faSortUp,
  faSortDown,
  faSort,
  faCaretUp,
  faCaretDown,
  faUserPlus,
  faSignal
);

// @ts-ignore
const renderMethod = module.hot ? render : hydrate;

Loadable.preloadReady().then(() => {
  renderMethod(<App />, document.getElementById('root'));
});
