import React from 'react';
import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import SdkLoader from 'views/components/sdk-loader';
import ScreenLoading from 'views/ui/screen-loading';
import Routes from 'routes';
import routesConfig from 'routes/config';
import { persistor, store } from 'store';
import history from 'store/history';

const App = () => (
  <Sentry.ErrorBoundary>
    <Provider store={store}>
      <PersistGate loading={<ScreenLoading />} persistor={persistor}>
        <SdkLoader>
          <ConnectedRouter history={history}>
            <Routes store={store} routes={routesConfig} />
          </ConnectedRouter>
        </SdkLoader>
      </PersistGate>
    </Provider>
  </Sentry.ErrorBoundary>
);

export default Sentry.withProfiler(App);
