import { call, put, select, takeLatest } from 'redux-saga/effects';
import SDK from 'utils/sdk';
import { getMeItem } from 'modules/me/selectors';
import { retrieveMe } from './actions';

export function* retrieveMeSaga() {
  try {
    yield put(retrieveMe.request());
    const { id } = yield select(getMeItem);
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.users, 'getMe'], { id });
    yield put(retrieveMe.success(body));
  } catch (error) {
    yield put(retrieveMe.failure(error));
  } finally {
    yield put(retrieveMe.fulfill());
  }
}

export default function* meWatcher() {
  yield takeLatest(retrieveMe.TRIGGER, retrieveMeSaga);
}
