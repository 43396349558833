import Loadable from 'react-loadable';
import ScreenLoading from 'views/ui/screen-loading';
import { listGroups } from 'modules/groups/actions';
import { listAgents, listMeClients, getClient } from 'modules/clients/actions';
import { getMeReports, getPerformanceGraph } from 'modules/reports/actions';
import { checkLoggedInRC } from 'modules/ringcentral/actions';
import { listInsurances } from 'modules/insurances/actions';
import { retrieveLead, listLeads, clearLead } from 'modules/leads/actions';
import { getInboundScript } from 'modules/scripts/actions';

const Root = Loadable({
  loader: () => import('views/screens/login'),
  loading: ScreenLoading,
});

const Callback = Loadable({
  loader: () => import('views/components/callback'),
  loading: ScreenLoading,
});

const ContainerDashboard = Loadable({
  loader: () => import('views/containers/leads'),
  loading: ScreenLoading,
});

const ContainerLeadProfile = Loadable({
  loader: () => import('views/containers/lead'),
  loading: ScreenLoading,
});

const ContainerReports = Loadable({
  loader: () => import('views/containers/reports'),
  loading: ScreenLoading,
});

const Clients = Loadable({
  loader: () => import('views/screens/clients'),
  loading: ScreenLoading,
});

const Dashboard = Loadable({
  loader: () => import('views/screens/list-leads'),
  loading: ScreenLoading,
});

const LeadProfile = Loadable({
  loader: () => import('views/screens/lead-profile'),
  loading: ScreenLoading,
});

const NewLead = Loadable({
  loader: () => import('views/screens/lead-new'),
  loading: ScreenLoading,
});

const Reports = Loadable({
  loader: () => import('views/screens/reports'),
  loading: ScreenLoading,
});

const routes = [
  {
    path: '/',
    component: Root,
    exact: true,
    published: true,
  },
  {
    path: '/login',
    component: Root,
    exact: true,
    published: true,
  },
  {
    path: '/clients',
    component: Clients,
    exact: true,
    privated: true,
    actions: [listMeClients],
  },
  {
    path: '/callback',
    component: Callback,
    exact: true,
    privated: true,
  },
  {
    path: '/clients/:id/lead',
    component: ContainerLeadProfile,
    privated: true,
    actions: [getClient, listAgents, checkLoggedInRC, listInsurances],
    routes: [
      {
        path: '/clients/:id/lead/:leadId',
        component: LeadProfile,
        exact: true,
        actions: [listGroups, retrieveLead],
        actionsUnMount: [clearLead],
        payload: ['dropdown'],
      },
      {
        path: '/clients/:id/lead',
        component: NewLead,
        actions: [getInboundScript],
        exact: true,
      },
    ],
  },
  {
    path: '/clients/:id/reports',
    component: ContainerReports,
    privated: true,
    actions: [getMeReports, getPerformanceGraph],
    routes: [
      {
        path: '/clients/:id/reports',
        component: Reports,
        exact: true,
      },
    ],
  },
  {
    path: '/clients/:id',
    component: ContainerDashboard,
    privated: true,
    actions: [getClient],
    routes: [
      {
        path: '/clients/:id',
        component: Dashboard,
        exact: true,
        actions: [listGroups, listLeads, checkLoggedInRC],
        payload: ['tab'],
      },
      {
        path: '/clients/:id/trainning',
        exact: true,
      },
    ],
  },

  {
    name: '404',
    path: '*',
    component: () => null,
  },
];

export default routes;
