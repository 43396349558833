import { all, call, put, takeLatest } from 'redux-saga/effects';
import SDK from 'utils/sdk';
import { listInsurances } from './actions';

export function* listInsurancesSaga({ payload: { id } }) {
  try {
    yield put(listInsurances.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.insurances, 'getInsurances'], { id });
    yield put(listInsurances.success(body));
  } catch (error) {
    yield put(listInsurances.failure(error));
  } finally {
    yield put(listInsurances.fulfill());
  }
}

export default function* insurancesWatcher() {
  yield all([takeLatest(listInsurances.TRIGGER, listInsurancesSaga)]);
}
