import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import history from 'store/history';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DNS,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  tracesSampleRate: 1.0,
  normalizeDepth: 10,
  release: `cloudpotato@${process.env.npm_package_version}`,
  environment: process.env.NODE_ENV === 'production' ? 'production' : 'staging',
});
