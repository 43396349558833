import { createSelector } from 'reselect';
import { isClientsLoading } from 'modules/clients/selectors';

export const getLeads = (state) => state.leads;

export const getLeadsError = createSelector(getLeads, (leads) => leads.error);
export const getLeadsItems = createSelector(getLeads, (leads) => leads.items);
export const getLeadItem = createSelector(getLeads, (leads) => leads.item);
export const getLeadFilter = createSelector(getLeads, (leads) => leads.filter);

export const getLeadOptions = createSelector(
  getLeads,
  (leads) => leads.options
);

export const isLeadsLoading = createSelector(
  getLeads,
  (leads) => leads.isLeadsLoading
);

export const isLeadLoading = createSelector(
  getLeads,
  (leads) => leads.isLeadLoading
);

export const isOptionsLoading = createSelector(
  getLeads,
  (leads) => leads.isOptionsLoading
);

export const isLoadingProfile = createSelector(
  [isLeadLoading, isClientsLoading, getLeadItem],
  (loadingLead, loadingClient, leadExists) => {
    return loadingLead || loadingClient || leadExists === null;
  }
);
