// @ts-nocheck
/* eslint-disable no-underscore-dangle */
import SDK from 'ringcentral';
import WebPhone from 'ringcentral-web-phone';
import incomingAudio from 'ringcentral-web-phone/audio/incoming.ogg';
import outgoingAudio from 'ringcentral-web-phone/audio/outgoing.ogg';

const remoteVideoElement = document.getElementById('remoteVideo');
const localVideoElement = document.getElementById('localVideo');

const redirectUri = decodeURIComponent(
  `${window.location.origin.split('login', 1)}/callback`
);

const {
  REACT_APP_RC_KEY,
  REACT_APP_RC_SECRET,
  REACT_APP_RC_SERVER,
  REACT_APP_RC_LOGLEVEL,
} = process.env;

export const server = REACT_APP_RC_SERVER;
export const appKey = REACT_APP_RC_KEY;
export const appSecret = REACT_APP_RC_SECRET;
export const logLevel = REACT_APP_RC_LOGLEVEL;

const sdk = {
  _extension: null,
  _webPhone: null,
  _presence: null,
  _session: null,
  _platform: null,
  _username: null,
  _sip: null,
  _sdk: null,
  init: () => {
    if (sdk._sdk) return false;
    sdk._sdk = new SDK({
      appKey,
      appSecret,
      server,
      redirectUri,
    });

    sdk._platform = sdk._sdk.platform();
    return sdk._sdk;
  },

  login: async () => {
    await sdk._platform
      .loginWindow({ url: sdk._platform.loginUrl() })
      .then(sdk._platform.login.bind(sdk._platform))
      .then(async () => {
        localStorage.setItem('webPhoneServer', server || '');
        localStorage.setItem('webPhoneAppKey', appKey || '');
        localStorage.setItem('webPhoneAppSecret', appSecret || '');
        await sdk.getPresence();
        await sdk.setExtension();
        await sdk.setWebPhone();
      });
  },

  logout: () => {
    sdk._platform.logout();
  },

  isLoggedIn: async () => {
    const isLogged = await sdk._platform.loggedIn();
    return isLogged;
  },

  setExtension: async () => {
    const res = await sdk._platform.get('/restapi/v1.0/account/~/extension/~');
    sdk._extension = res.json();
  },

  getSipProvision: async () => {
    if (sdk._sip) return sdk._sip;
    const res = await sdk._platform.post('/client-info/sip-provision', {
      sipInfo: [
        {
          transport: 'WSS',
        },
      ],
    });
    sdk._sip = res.json();
    return sdk._sip;
  },

  setPresence: async (formData) => {
    const res = await sdk._platform.put(
      '/restapi/v1.0/account/~/extension/~/presence',
      formData
    );
    sdk._presence = res.json();
    return sdk._presence;
  },

  getPresence: async () => {
    if (sdk._presence) return sdk._presence;
    const res = await sdk._platform.get(
      '/restapi/v1.0/account/~/extension/~/presence'
    );
    sdk._presence = res.json();
    return sdk._presence;
  },

  getWebPhone: () => {
    return sdk._webPhone;
  },

  setWebPhone: async () => {
    if (sdk._webPhone) return sdk._webPhone;
    const data = await sdk.getSipProvision();
    sdk._webPhone = new WebPhone(data, {
      appKey: localStorage.getItem('webPhoneAppKey'),
      audioHelper: {
        enabled: true,
      },
      logLevel: parseInt(logLevel, 10),
      appName: 'cloudpotato-app',
      appVersion: '1.0.0',
      media: {
        remote: remoteVideoElement, // HTML ELEMENT IN PUBLIC/INDEX.HTML FOR AUDIO/VIDEO/MIC
        local: localVideoElement, // HTML ELEMENT IN PUBLIC/INDEX.HTML FOR AUDIO/VIDEO/MIC
      },
      enableQos: true,
      enableMediaReportLogging: true,
    });

    sdk._webPhone.userAgent.audioHelper.loadAudio({
      incoming: incomingAudio,
      outgoing: outgoingAudio,
    });

    sdk._webPhone.userAgent.audioHelper.setVolume(0.3);

    return sdk._webPhone;
  },

  getSession: () => {
    return sdk._session || false;
  },

  setSession: (session) => {
    sdk._session = session;
    return sdk._session;
  },

  outboundCall: async (number) => {
    sdk._session = await sdk._webPhone.userAgent.invite(number);
  },

  accept: () => {
    return sdk._session.accept();
  },

  reject: () => {
    sdk._session.reject();
  },

  hold: () => {
    return sdk._session.hold();
  },

  unhold: () => {
    return sdk._session.unhold();
  },

  mute: () => {
    sdk._session.mute();
  },

  unmute: () => {
    sdk._session.unmute();
  },

  hangup: () => {
    sdk._session.terminate();
  },

  transfer: (number) => {
    return sdk._session.transfer(number);
  },
};

export default sdk;
