import { bindRoutineToReduxForm, createRoutine } from 'redux-saga-routines';
import { LOGIN_USER, SIGN_UP_USER, LOGOUT_USER } from './types';

export const loginUser = createRoutine(LOGIN_USER);
export const bindedLoginUser = bindRoutineToReduxForm(loginUser);

export const signUpUser = createRoutine(SIGN_UP_USER);
export const bindedSignUpUser = bindRoutineToReduxForm(signUpUser);

export const logoutUser = createRoutine(LOGOUT_USER);
