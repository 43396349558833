/* eslint-disable default-case */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  retrieveLead,
  listLeads,
  createNote,
  searchLead,
  nextLead,
  createLead,
  reschedule,
  changeGroup,
  nextGroup,
  updateLead,
  voicemail,
} from './actions';
import { SET_FILTER, CLEAR_LEAD } from './types';

const initialState = {
  error: null,
  items: [],
  item: null,
  options: [],
  filter: null,
  reports: null,
  script: null,
  defaultScript: [],
  isLeadsloading: false,
  isLeadloading: false,
  isOptionsLoading: false,
  isScriptLoading: false,
  isDefaultScriptLoading: false,
};

const reducer = produce((draft, { type, payload }) => {
  switch (type) {
    case listLeads.TRIGGER:
      draft.isLeadsloading = true;
      draft.error = null;
      break;
    case createNote.TRIGGER:
    case retrieveLead.TRIGGER:
    case createLead.TRIGGER:
    case updateLead.TRIGGER:
    case changeGroup.TRIGGER:
    case nextGroup.TRIGGER:
    case reschedule.TRIGGER:
      draft.isLeadloading = true;
      draft.error = null;
      break;
    case searchLead.TRIGGER:
      draft.isOptionsloading = true;
      draft.error = null;
      break;
    case voicemail.TRIGGER:
      draft.isScriptLoading = true;
      break;
    case nextLead.TRIGGER:
      draft.items.shift();
      break;

    // SUCCESS

    case listLeads.SUCCESS:
      draft.items = payload;
      break;
    case searchLead.SUCCESS:
      draft.options = payload;
      break;
    case createNote.SUCCESS:
      draft.item.notes.push(payload);
      break;
    case retrieveLead.SUCCESS:
    case updateLead.SUCCESS:
    case createLead.SUCCESS:
    case voicemail.SUCCESS:
    case changeGroup.SUCCESS:
      draft.item = payload;
      break;
    // FAILURE

    case listLeads.FAILURE:
    case retrieveLead.FAILURE:
    case createNote.FAILURE:
    case createLead.FAILURE:
    case reschedule.FAILURE:
    case updateLead.FAILURE:
    case searchLead.FAILURE:
    case changeGroup.FAILURE:
    case voicemail.FAILURE:
    case nextGroup.FAILURE:
      draft.error = payload;
      break;

    // FULFILL

    case listLeads.FULFILL:
      draft.isLeadsloading = false;
      break;
    case retrieveLead.FULFILL:
    case createNote.FULFILL:
    case createLead.FULFILL:
    case updateLead.FULFILL:
    case changeGroup.FULFILL:
    case nextGroup.FULFILL:
    case reschedule.FULFILL:
      draft.isLeadloading = false;
      break;
    case searchLead.FULFILL:
      draft.isOptionsloading = false;
      break;
    case voicemail.FULFILL:
      draft.isScriptLoading = false;
      break;

    // PLAIN

    case SET_FILTER:
      draft.filter = payload;
      break;
    case CLEAR_LEAD:
      draft.item = null;
      break;
  }
}, initialState);

export default reducer;
