import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as form } from 'redux-form';
import { persistReducer } from 'redux-persist';

import auth from 'modules/auth/reducer';
import clients from 'modules/clients/reducer';
import me from 'modules/me/reducer';
import groups from 'modules/groups/reducer';
import leads from 'modules/leads/reducer';
import insurances from 'modules/insurances/reducer';
import ringcentral from 'modules/ringcentral/reducer';
import scripts from 'modules/scripts/reducer';
import reports from 'modules/reports/reducer';
import { logoutUser } from 'modules/auth/actions';

import { authPersistConfig, rootPersistConfig } from './persistor';
import routerHistory from './history';

const appReducer = combineReducers({
  // external
  form,
  router: connectRouter(routerHistory),

  // app
  auth: persistReducer(authPersistConfig, auth),
  clients,
  me,
  groups,
  leads,
  insurances,
  scripts,
  reports,
  ringcentral,
});

const actions = [logoutUser.SUCCESS];

const rootReducer = (state, action) => {
  if (actions.includes(action.type)) {
    return appReducer({}, action);
  }

  return appReducer(state, action);
};

export default persistReducer(rootPersistConfig, rootReducer);
