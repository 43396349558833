/* eslint-disable default-case */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  login,
  logout,
  answerIncomingCall,
  getPresence,
  setCallingMode,
  incomingCall,
  handleMute,
  handleHold,
  rejectIncomingCall,
} from './actions';
import { ACCEPTED_CALL, SET_FINAL_TIME } from './types';

const initialState = {
  loading: false,
  error: null,
  isLoggedIn: false,
  presence: null,
  calling: false,
  onCall: false,
  isMuted: false,
  isHolded: false,
  isSession: false,
  startTime: null,
  endTime: null,
};

const reducer = produce((draft, { type, payload }) => {
  switch (type) {
    case login.TRIGGER:
    case logout.TRIGGER:
    case getPresence.TRIGGER:
    case setCallingMode.TRIGGER:
      draft.loading = true;
      draft.error = null;
      break;
    case incomingCall.TRIGGER:
      draft.calling = true;
      break;
    case answerIncomingCall.SUCCESS:
      draft.onCall = true;
      draft.calling = false;
      draft.isSession = false;
      break;
    case rejectIncomingCall.SUCCESS:
      draft.onCall = false;
      draft.calling = false;
      draft.isMuted = false;
      break;
    case login.SUCCESS:
      draft.isLoggedIn = true;
      break;
    case logout.SUCCESS:
      draft.isLoggedIn = false;
      break;
    case incomingCall.SUCCESS:
      draft.isSession = true;
      break;
    case getPresence.SUCCESS:
    case setCallingMode.SUCCESS:
      draft.presence = payload;
      break;
    case handleMute.SUCCESS:
      draft.isMuted = payload;
      break;
    case handleHold.SUCCESS:
      draft.isHolded = payload;
      break;
    case login.FAILURE:
    case logout.FAILURE:
    case getPresence.FAILURE:
    case setCallingMode.FAILURE:
      draft.error = payload;
      break;
    case login.FULFILL:
    case logout.FULFILL:
    case getPresence.FULFILL:
    case setCallingMode.FULFILL:
      draft.loading = false;
      break;
    case ACCEPTED_CALL:
      draft.onCall = true;
      draft.startTime = payload;
      break;
    case SET_FINAL_TIME:
      draft.endTime = payload;
      break;
  }
}, initialState);

export default reducer;
