/* eslint-disable no-use-before-define */
/* eslint-disable no-console */
import {
  all,
  call,
  put,
  takeLatest,
  take,
  fork,
  select,
} from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import { push } from 'connected-react-router';
import sdk from 'utils/ringcentral';
import { getClientCurrent } from 'modules/clients/selectors';
import {
  login,
  logout,
  outboundCall,
  websocket,
  checkLoggedInRC,
  getPresence,
  setCallingMode,
  incomingCall,
  handleMute,
  handleHold,
  handleTransfer,
  handleTerminate,
  answerIncomingCall,
  rejectIncomingCall,
} from './actions';
import { ACCEPTED_CALL, SET_FINAL_TIME } from './types';

export function* checkRingcentralSessionSaga() {
  try {
    yield put(checkLoggedInRC.request());
    yield call([sdk, 'init']);
    const loggedIn = yield call([sdk, 'isLoggedIn']);
    if (!loggedIn) {
      yield put(logout.success());
      yield put(login.trigger());
    } else {
      yield put(login.success());
      yield put(getPresence.trigger());
      yield put(websocket.trigger());
    }
    yield put(checkLoggedInRC.success());
  } catch (error) {
    yield put(logout.trigger());
    yield put(checkLoggedInRC.failure(error));
  } finally {
    yield put(checkLoggedInRC.fulfill());
  }
}

export function* loginRingcentralSaga() {
  try {
    yield put(login.request());
    yield call([sdk, 'login']);
    yield put(getPresence.trigger());
    yield put(websocket.trigger());
    yield put(login.success());
  } catch (error) {
    yield put(login.failure(error.message));
  } finally {
    yield put(login.fulfill());
  }
}

export function* logoutRingcentralSaga() {
  try {
    yield put(logout.request());
    yield call([sdk, 'logout']);
    yield put(logout.success());
  } catch (error) {
    yield put(logout.failure(error.message));
  } finally {
    yield put(logout.fulfill());
  }
}

export function* setCallingModeSaga({ payload }) {
  try {
    yield put(setCallingMode.request());
    const formData = {
      dndStatus: payload,
    };
    const data = yield call([sdk, 'setPresence'], formData);
    yield put(setCallingMode.success(data));
  } catch (error) {
    yield put(setCallingMode.failure(error.message));
  } finally {
    yield put(setCallingMode.fulfill());
  }
}

export function* getUserPresenceSaga() {
  try {
    yield put(getPresence.request());
    const data = yield call([sdk, 'getPresence']);
    yield put(getPresence.success(data));
  } catch (error) {
    yield put(getPresence.failure(error.message));
  } finally {
    yield put(getPresence.fulfill());
  }
}

export function* getRingcentralWebPhone() {
  try {
    yield put(websocket.request());
    yield call([sdk, 'setWebPhone']);
    yield call(webphoneChannel);
    yield put(websocket.success());
  } catch (error) {
    yield put(websocket.failure(error.message));
  } finally {
    yield put(websocket.fulfill());
  }
}

export function* outboundCallRCSaga({ payload: { number } }) {
  try {
    yield put(outboundCall.request());
    yield call([sdk, 'outboundCall'], number);
    yield call(sessionChannel);
    yield put(outboundCall.success());
  } catch (error) {
    yield put(outboundCall.failure(error.message));
  } finally {
    yield put(outboundCall.fulfill());
  }
}

export function* muteHandlerSaga({ payload }) {
  try {
    yield put(handleMute.request());
    const action = payload ? 'unmute' : 'mute';
    yield call([sdk, action]);
    yield put(handleMute.success(!payload));
  } catch (error) {
    yield put(handleMute.failure(error));
  } finally {
    yield put(handleMute.fulfill());
  }
}

export function* holdHandlerSaga({ payload }) {
  try {
    yield put(handleHold.request());
    const action = payload ? 'unhold' : 'hold';
    yield call([sdk, action]);
    yield put(handleHold.success(!payload));
  } catch (error) {
    yield put(handleHold.failure(error));
  } finally {
    yield put(handleHold.fulfill());
  }
}

export function* transferHandlerSaga({ payload }) {
  try {
    yield put(handleTransfer.request());
    yield call([sdk, 'transfer'], payload);
    yield put(handleTransfer.success());
  } catch (error) {
    yield put(handleTransfer.failure());
  } finally {
    yield put(handleTransfer.fulfill());
  }
}

export function* terminateHandlerSaga() {
  try {
    yield put(handleTerminate.request());
    yield call([sdk, 'hangup']);
    yield put(handleTerminate.success());
  } catch (error) {
    yield put(handleTerminate.failure(error));
  } finally {
    yield put(handleTerminate.fulfill());
  }
}

export function* acceptCallHandlerSaga() {
  try {
    const client = yield select(getClientCurrent);
    yield put(answerIncomingCall.request());
    yield call([sdk, 'accept']);
    yield put(push(`/clients/${client.id}/lead`));
    yield put(answerIncomingCall.success());
  } catch (error) {
    yield put(answerIncomingCall.failure(error));
  } finally {
    yield put(answerIncomingCall.fulfill());
  }
}

export function* rejectCallHandlerSaga() {
  try {
    yield put(rejectIncomingCall.request());
    yield call([sdk, 'reject']);
    yield call([sdk, 'setSession'], null);
    yield put(rejectIncomingCall.success());
  } catch (error) {
    yield put(rejectIncomingCall.failure(error));
  } finally {
    yield put(rejectIncomingCall.fulfill());
  }
}

export function* setSessionChannelSaga({ payload }) {
  try {
    yield put(incomingCall.request());
    yield call([sdk, 'setSession'], payload);
    yield fork(sessionChannel);
    yield put(incomingCall.success());
  } catch (error) {
    yield put(incomingCall.failure(error));
  } finally {
    yield put(incomingCall.fulfill());
  }
}

function createWebphoneChannel() {
  return eventChannel((emit) => {
    const webphone = sdk.getWebPhone();
    const errorHandler = (errorEvent) => {
      localStorage.clear();
      emit(checkLoggedInRC());
      emit(new Error(errorEvent));
    };

    // eslint-disable-next-line no-unused-vars
    const anonHandler = (event) => (trace) => {
      // Consoles log for debugging in Development
      // console.log(event);
      // console.log(trace);
    };

    webphone.userAgent.on('invite', (session) => {
      emit(incomingCall(session));
    });
    webphone.userAgent.on('connecting', anonHandler('connecting'));
    webphone.userAgent.on('connected', anonHandler('connected'));
    webphone.userAgent.on('disconnected', anonHandler('disconnected'));
    webphone.userAgent.on('registered', anonHandler('Registered'));
    webphone.userAgent.on('unregistered', anonHandler('unregistered'));
    webphone.userAgent.on('registrationFailed', errorHandler);
    webphone.userAgent.on('message', anonHandler('message'));
    webphone.userAgent.transport.on('closed', anonHandler('closed'));
    webphone.userAgent.transport.on(
      'transportError',
      anonHandler('transportError')
    );
    webphone.userAgent.transport.on(
      'wsConnectionError',
      anonHandler('wsConnectionError')
    );
    webphone.userAgent.transport.on('switchBackProxy', () =>
      webphone.userAgent.transport.reconnect(true)
    );

    const unsuscribe = () => {};

    return unsuscribe;
  });
}

function createSessionChannel() {
  return eventChannel((emit) => {
    const session = sdk.getSession();
    // eslint-disable-next-line no-unused-vars
    const errorHandler = (errorEvent) => {
      emit(new Error(errorEvent.reason));
    };

    // eslint-disable-next-line no-unused-vars
    const anonHandler = (event) => (trace) => {
      // Consoles log for debugging in Development
      // console.log(event);
      // console.log(trace);
    };

    // eslint-disable-next-line no-unused-vars
    const clearSession = (errorEvent) => (error) => {
      // Consoles log for debugging in Development
      // console.log(errorEvent);
      // console.log(error);
      sdk.setSession(null);
      emit(rejectIncomingCall.success());
    };

    session.on('accepted', () =>
      emit({ type: ACCEPTED_CALL, payload: session.startTime })
    );
    session.on('terminated', () =>
      emit({ type: SET_FINAL_TIME, payload: session.endTime })
    );
    session.on('rejected', clearSession('rejected'));
    session.on('unmuted', anonHandler('unmuted'));
    session.on('muted', anonHandler('muted'));
    session.on('connecting', anonHandler('connecting'));
    session.on('progress', anonHandler('progress'));
    session.on('cancel', clearSession('cancel'));
    session.on('refer', anonHandler('refer'));
    session.on('dtmf', anonHandler('dtmf'));
    session.on('failed', clearSession('failed'));
    session.on('replaced', (newSession) => {
      sdk.setSession(newSession);
    });
    session.on('bye', clearSession('bye'));

    const unsuscribe = () => {};

    return unsuscribe;
  });
}

export function* webphoneChannel() {
  const channelWebphone = yield call(createWebphoneChannel);
  while (true) {
    const actionWebphone = yield take(channelWebphone);
    yield put(actionWebphone);
  }
}

export function* sessionChannel() {
  const channelSession = yield call(createSessionChannel);
  while (true) {
    const actionSession = yield take(channelSession);
    yield put(actionSession);
  }
}

export default function* ringcentralWatch() {
  yield all([takeLatest(checkLoggedInRC.TRIGGER, checkRingcentralSessionSaga)]);
  yield all([takeLatest(login.TRIGGER, loginRingcentralSaga)]);
  yield all([takeLatest(logout.TRIGGER, logoutRingcentralSaga)]);
  yield all([takeLatest(getPresence.TRIGGER, getUserPresenceSaga)]);
  yield all([takeLatest(setCallingMode.TRIGGER, setCallingModeSaga)]);
  yield all([takeLatest(websocket.TRIGGER, getRingcentralWebPhone)]);
  yield all([takeLatest(outboundCall.TRIGGER, outboundCallRCSaga)]);
  yield all([takeLatest(incomingCall.TRIGGER, setSessionChannelSaga)]);
  yield all([takeLatest(handleMute.TRIGGER, muteHandlerSaga)]);
  yield all([takeLatest(handleHold.TRIGGER, holdHandlerSaga)]);
  yield all([takeLatest(handleTransfer.TRIGGER, transferHandlerSaga)]);
  yield all([takeLatest(handleTerminate.TRIGGER, terminateHandlerSaga)]);
  yield all([takeLatest(answerIncomingCall.TRIGGER, acceptCallHandlerSaga)]);
  yield all([takeLatest(rejectIncomingCall.TRIGGER, rejectCallHandlerSaga)]);
}
