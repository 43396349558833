export const LOGIN_RINGCENTRAL = 'LOGIN_RINGCENTRAL';
export const LOGOUT_RINGCENTRAL = 'LOGOUT_RINGCENTRAL';
export const CHECK_LOGGED_IN = 'CHECK_LOGGED_IN';

export const SET_CALLING_MODE = 'SET_CALLING_MODE';
export const GET_PRESENCE = 'GET_PRESENCE';

export const WEBSOCKET_RINGCENTRAL = 'WEBSOCKET_RINGCENTRAL';
export const EXTENSION_RINGCENTRAL = 'EXTENSION_RINGCENTRAL';

export const SET_SESSION = 'SET_SESSION';

export const OUTBOUND_CALL = 'OUTBOUND_CALL';
export const INCOMING_CALL = 'INCOMING_CALL';
export const CALL_LEAD = 'CALL_LEAD';

export const ANSWER_INCOMING_CALL = 'ANSWER_INCOMING_CALL';
export const REJECT_INCOMING_CALL = 'REJECT_INCOMING_CALL';

export const ACCEPTED_CALL = 'ACCEPTED_CALL';
export const SET_FINAL_TIME = 'SET_FINAL_TIME';

export const TERMINATE = 'TERMINATE';
export const MUTE = 'MUTE';
export const HOLD = 'HOLD';
export const TRANSFER = 'TRANSFER';
