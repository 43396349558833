import { createSelector } from 'reselect';

export const getClients = (state) => state.clients;

export const isClientsLoading = createSelector(
  getClients,
  (clients) => clients.loading
);

export const getClientsError = createSelector(
  getClients,
  (clients) => clients.error
);

export const getClientsItems = createSelector(
  getClients,
  (clients) => clients.items
);

export const getClientsAgents = createSelector(
  getClients,
  (clients) => clients.agents
);

export const getClientCurrent = createSelector(
  getClients,
  (clients) => clients.current
);
