/* eslint-disable import/no-cycle */
/* eslint-disable no-useless-catch */
import { routinePromiseWatcherSaga } from 'redux-saga-routines';
import { all, call } from 'redux-saga/effects';

import authWatch from 'modules/auth/sagas';
import clientsWatcher from 'modules/clients/sagas';
import groupsWatcher from 'modules/groups/sagas';
import meWatch from 'modules/me/sagas';
import leadsWatcher from 'modules/leads/sagas';
import insurancesWatcher from 'modules/insurances/sagas';
import ringcentralWatcher from 'modules/ringcentral/sagas';
import scriptsWatcher from 'modules/scripts/sagas';
import reportsWatcher from 'modules/reports/sagas';

function* rootSaga() {
  try {
    yield all([
      // external
      call(routinePromiseWatcherSaga),

      // app
      call(authWatch),
      call(clientsWatcher),
      call(groupsWatcher),
      call(meWatch),
      call(leadsWatcher),
      call(insurancesWatcher),
      call(ringcentralWatcher),
      call(scriptsWatcher),
      call(reportsWatcher),
    ]);
  } catch (error) {
    throw error;
  }
}

export default rootSaga;
