import { isGraphLoading, isMeReportsLoading } from 'modules/reports/selectors';
import { createSelector } from 'reselect';

export const getMe = (state) => state.me;
export const isMeLoading = createSelector(getMe, (me) => me.loading);
export const getMeError = createSelector(getMe, (me) => me.error);
export const getMeItem = createSelector(getMe, (me) => me.item);

export const isReportsLoading = createSelector(
  [isGraphLoading, isMeReportsLoading],
  (graphLoading, reportLoading) => {
    return graphLoading || reportLoading;
  }
);
