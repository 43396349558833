/* eslint-disable default-case */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  getScript,
  getDefaultScript,
  getInboundScript,
  createLeadFromScript,
  saveScript,
} from './actions';

const initialState = {
  error: null,
  leadScript: null,
  inboundScript: null,
  defaultScript: null,
  loading: false,
  loadingLeadScript: false,
  loadingDefaultScript: false,
  loadingInboundScript: false,
};

const reducer = produce((draft, { type, payload }) => {
  switch (type) {
    case getScript.TRIGGER:
      draft.loadingLeadScript = true;
      draft.error = null;
      break;
    case getDefaultScript.TRIGGER:
      draft.loadingDefaultScript = true;
      draft.error = null;
      break;
    case getInboundScript.TRIGGER:
      draft.loadingInboundScript = true;
      draft.error = null;
      break;

    case createLeadFromScript.TRIGGER:
    case saveScript.TRIGGER:
      draft.loading = true;
      draft.error = null;
      break;

    // SUCCESS
    case getScript.SUCCESS:
      draft.leadScript = payload;
      break;
    case getDefaultScript.SUCCESS:
      draft.defaultScript = payload;
      break;
    case getInboundScript.SUCCESS:
      draft.inboundScript = payload;
      break;

    // FAILURE
    case getScript.FAILURE:
    case getDefaultScript.FAILURE:
    case getInboundScript.FAILURE:
    case createLeadFromScript.FAILURE:
    case saveScript.FAILURE:
      draft.error = payload;
      break;

    // FULFILL
    case getScript.FULFILL:
      draft.loadingLeadScript = false;
      break;
    case getDefaultScript.FULFILL:
      draft.loadingDefaultScript = false;
      break;
    case getInboundScript.FULFILL:
      draft.loadingInboundScript = false;
      break;

    case createLeadFromScript.FULFILL:
    case saveScript.FULFILL:
      draft.loading = false;
      break;
  }
}, initialState);

export default reducer;
