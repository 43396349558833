import { createRoutine, bindRoutineToReduxForm } from 'redux-saga-routines';
import {
  GET_SCRIPT,
  GET_DEFAULT_SCRIPT,
  GET_INBOUND_SCRIPT,
  CREATE_LEAD_FROM_SCRIPT,
  SAVE_SCRIPT,
} from './types';

export const getScript = createRoutine(GET_SCRIPT);
export const getDefaultScript = createRoutine(GET_DEFAULT_SCRIPT);
export const getInboundScript = createRoutine(GET_INBOUND_SCRIPT);
export const createLeadFromScript = createRoutine(CREATE_LEAD_FROM_SCRIPT);
export const saveScript = createRoutine(SAVE_SCRIPT);

export const bindedSaveScript = bindRoutineToReduxForm(saveScript);
export const bindedCreateLeadFromScript = bindRoutineToReduxForm(
  createLeadFromScript
);
