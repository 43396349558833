import { all, call, put, cancel, takeLatest } from 'redux-saga/effects';
import { SubmissionError } from 'redux-form';
import * as Sentry from '@sentry/react';
import SDK from 'utils/sdk';
import { retrieveMe } from 'modules/me/actions';
import { loginUser, logoutUser } from './actions';

export function* loginUserSaga({ payload: { values: requestBody } }) {
  try {
    yield put(loginUser.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.auth, 'login'], {}, { requestBody });
    yield call([Sentry, 'setUser'], body.user);
    yield call([SDK, 'setToken'], body.accessToken);
    yield put(retrieveMe.success(body.user));
    yield put(loginUser.success(body.accessToken));
  } catch (error) {
    yield put(
      loginUser.failure(new SubmissionError({ _error: error.message }))
    );
  } finally {
    yield put(loginUser.fulfill());
  }
}

export function* logoutUserSaga() {
  try {
    yield put(logoutUser.request());
    yield call([Sentry, 'setUser'], {});
    yield put(logoutUser.success());
  } catch (error) {
    yield put(logoutUser.failure(error));
  } finally {
    yield put(logoutUser.fulfill());
  }
}

export function* checkSessionSaga(error) {
  try {
    if (error.response.status === 401) {
      yield call(logoutUserSaga);
      yield cancel();
    }
  } catch {
    yield cancel();
  }
}

export default function* authWatch() {
  yield all([
    takeLatest(loginUser.TRIGGER, loginUserSaga),
    takeLatest(logoutUser.TRIGGER, logoutUserSaga),
  ]);
}
