/* eslint-disable default-case */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import { listMeClients, getClient, listAgents } from './actions';

const initialState = {
  error: null,
  items: [],
  agents: [],
  loading: false,
  current: null,
};

const reducer = produce((draft, { type, payload }) => {
  switch (type) {
    case listMeClients.TRIGGER:
    case getClient.TRIGGER:
    case listAgents.TRIGGER:
      draft.loading = true;
      draft.error = null;
      break;
    case getClient.SUCCESS:
      draft.current = payload;
      break;
    case listMeClients.SUCCESS:
      draft.items = payload;
      break;
    case listAgents.SUCCESS:
      draft.agents = payload;
      break;
    case listMeClients.FAILURE:
    case getClient.FAILURE:
    case listAgents.FAILURE:
    case listMeClients.FULFILL:
    case getClient.FULFILL:
    case listAgents.FULFILL:
      draft.loading = false;
      break;
  }
}, initialState);

export default reducer;
