import { all, call, put, takeLatest } from 'redux-saga/effects';
import SDK from 'utils/sdk';
import { listGroups, setGroup, setGroupDropdown } from './actions';

export function* listGroupsSaga({ payload: { id, options } }) {
  try {
    yield put(listGroups.request());
    const params = {
      id,
      options,
    };
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.clients, 'getGroups1'], params);
    if (options === 'tab') {
      yield put(setGroup(body[0].id));
      yield put(listGroups.success(body));
    } else {
      yield put(setGroupDropdown(body));
    }
  } catch (error) {
    yield put(listGroups.failure(error));
  } finally {
    yield put(listGroups.fulfill());
  }
}

export default function* groupsWatcher() {
  yield all([takeLatest(listGroups.TRIGGER, listGroupsSaga)]);
}
