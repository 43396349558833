import { createSelector } from 'reselect';

export const getGroups = (state) => state.groups;

export const isGroupsLoading = createSelector(
  getGroups,
  (groups) => groups.loading
);

export const getGroupsError = createSelector(
  getGroups,
  (groups) => groups.error
);

export const getGroupsItems = createSelector(
  getGroups,
  (groups) => groups.items
);

export const getGroupsDropdown = createSelector(
  getGroups,
  (groups) => groups.dropdown
);

export const getGroupCurrent = createSelector(
  getGroups,
  (groups) => groups.current
);
