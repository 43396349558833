/* eslint-disable no-param-reassign */
import { select, call, put, takeLatest } from 'redux-saga/effects';
import SDK from 'utils/sdk';
import { getClientCurrent } from 'modules/clients/selectors';
import { getLeadItem } from 'modules/leads/selectors';
import { retrieveLead } from 'modules/leads/actions';
import {
  getScript,
  getDefaultScript,
  getInboundScript,
  createLeadFromScript,
  saveScript,
} from './actions';

export function* getScriptSaga({ payload: { id, lead } }) {
  try {
    yield put(getScript.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.scripts, 'getScripts'], {
      type: 'outbound',
      leadId: lead.id,
      groupId: lead.group.id,
      clientId: id,
    });
    yield put(getScript.success(body[0]));
  } catch (error) {
    yield put(getScript.failure(error.message));
  } finally {
    yield put(getScript.fulfill());
  }
}

export function* getDefaultScriptSaga({ payload: { id, lead } }) {
  try {
    yield put(getDefaultScript.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.scripts, 'getScripts'], {
      type: 'outbound',
      leadId: lead.id,
      clientId: id,
    });
    yield put(getDefaultScript.success(body));
  } catch (error) {
    yield put(getDefaultScript.failure(error.message));
  } finally {
    yield put(getDefaultScript.fulfill());
  }
}

export function* saveScriptSaga({ payload: { values } }) {
  try {
    yield put(saveScript.request());

    const response = Object.keys(values).reduce((accu, field) => {
      if (!('enabled' in values[field])) {
        if ('value' in values[field]) {
          if (values[field].value === true || values[field].value === false) {
            values[field].value = String(values[field].value);
          } else if (typeof values[field].value === 'object') {
            values[field].value = values[field].value.toString();
          }
          accu.push(values[field]);
        }
      }
      return accu;
    }, []);

    const { id } = yield select(getLeadItem);
    const client = yield select(getClientCurrent);
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api.leads, 'saveCustomValues'],
      { id },
      { requestBody: response }
    );
    yield put(saveScript.success(body));
    yield put(retrieveLead.trigger({ id: client.id, leadId: id }));
  } catch (error) {
    yield put(saveScript.failure(error.messages));
  } finally {
    yield put(saveScript.fulfill());
  }
}

export function* createLeadFromScriptSaga({ payload: { values } }) {
  try {
    yield put(createLeadFromScript.request());
    const client = yield select(getClientCurrent);
    const response = Object.keys(values).reduce((accu, field) => {
      if (!('enabled' in values[field])) {
        if (values[field].value === true || values[field].value === false) {
          values[field].value = String(values[field].value);
        } else if (typeof values[field].value === 'object') {
          values[field].value = values[field].value.toString();
        }
        accu.push(values[field]);
      }
      return accu;
    }, []);
    response.push.apply(response, [
      { name: 'groupId', model: 'lead', value: client.groupId },
      {
        name: 'subgroupId',
        model: 'lead',
        value: client.subgroupsId[0].id,
      },
      { name: 'clientId', model: 'lead', value: client.id },
      { name: 'priority', model: 'lead', value: 'High' },
    ]);

    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api.leads, 'createLeadByScript'],
      {},
      { requestBody: response }
    );
    yield put(createLeadFromScript.success());
    yield put(retrieveLead.success(body));
  } catch (error) {
    yield put(createLeadFromScript.failure(error.messages));
  } finally {
    yield put(createLeadFromScript.fulfill());
  }
}

export function* getInboundScriptSaga({ payload: { id } }) {
  try {
    yield put(getInboundScript.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.scripts, 'getScripts'], {
      clientId: id,
      type: 'inbound',
    });

    yield put(getInboundScript.success(body[0]));
  } catch (error) {
    yield put(getInboundScript.failure(error.message));
  } finally {
    yield put(getInboundScript.fulfill());
  }
}

export default function* clientsWatcher() {
  yield takeLatest(getScript.TRIGGER, getScriptSaga);
  yield takeLatest(getDefaultScript.TRIGGER, getDefaultScriptSaga);
  yield takeLatest(getInboundScript.TRIGGER, getInboundScriptSaga);
  yield takeLatest(createLeadFromScript.TRIGGER, createLeadFromScriptSaga);
  yield takeLatest(saveScript.TRIGGER, saveScriptSaga);
}
