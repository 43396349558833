import { createAction } from 'redux-actions';
import { createRoutine, bindRoutineToReduxForm } from 'redux-saga-routines';
import {
  RETRIEVE_LEAD,
  LIST_LEADS,
  CREATE_NOTE,
  SEARCH_LEAD,
  NEXT_LEAD,
  CREATE_LEAD,
  RESCHEDULE,
  CHANGE_GROUP,
  NEXT_GROUP,
  UPDATE_LEAD,
  VOICEMAIL,
  START_CALL,
  CLEAR_LEAD,
} from './types';

export const startCall = createAction(START_CALL);
export const clearLead = createAction(CLEAR_LEAD);

export const listLeads = createRoutine(LIST_LEADS);
export const createLead = createRoutine(CREATE_LEAD);
export const updateLead = createRoutine(UPDATE_LEAD);
export const searchLead = createRoutine(SEARCH_LEAD);
export const retrieveLead = createRoutine(RETRIEVE_LEAD);
export const nextLead = createRoutine(NEXT_LEAD);
export const createNote = createRoutine(CREATE_NOTE);
export const reschedule = createRoutine(RESCHEDULE);
export const changeGroup = createRoutine(CHANGE_GROUP);
export const nextGroup = createRoutine(NEXT_GROUP);
export const voicemail = createRoutine(VOICEMAIL);

export const bindedCreateLead = bindRoutineToReduxForm(createLead);
export const bindedUpdateLead = bindRoutineToReduxForm(updateLead);
export const bindedChangeGroup = bindRoutineToReduxForm(changeGroup);
