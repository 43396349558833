import { createSelector } from 'reselect';

export const getReports = (state) => state.reports;

export const getGraphReports = createSelector(
  getReports,
  (reports) => reports.graph
);

export const isGraphLoading = createSelector(
  getReports,
  (reports) => reports.loadingGraph
);

export const getMeReportsSelector = createSelector(
  getReports,
  (reports) => reports.reports
);

export const getReportsPeriod = createSelector(
  getReports,
  (reports) => reports.periodReport
);

export const isMeReportsLoading = createSelector(
  getReports,
  (reports) => reports.loadingReport
);
