import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import {
  LIST_ME_CLIENTS,
  CHANGE_CLIENT,
  GET_CLIENT,
  LIST_AGENTS,
} from './types';

export const listMeClients = createRoutine(LIST_ME_CLIENTS);
export const changeClient = createRoutine(CHANGE_CLIENT);
export const listAgents = createRoutine(LIST_AGENTS);
export const getClient = createRoutine(GET_CLIENT);

export const promisifyGetClient = promisifyRoutine(getClient);
