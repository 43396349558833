/* eslint-disable default-case */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import { getPerformanceGraph, getMeReports, setPeriodReport } from './actions';

const initialState = {
  error: null,
  graph: null,
  reports: null,
  periodReport: null,
  loadingReport: false,
  loadingGraph: false,
};

const reducer = produce((draft, { type, payload }) => {
  switch (type) {
    case getPerformanceGraph.TRIGGER:
      draft.loadingGraph = true;
      draft.error = null;
      break;
    case getMeReports.TRIGGER:
      draft.loadingReport = true;
      draft.error = null;
      break;
    case getPerformanceGraph.SUCCESS:
      draft.graph = payload;
      break;
    case getMeReports.SUCCESS:
      draft.reports = payload;
      break;
    case getPerformanceGraph.FAILURE:
    case getMeReports.FAILURE:
      draft.error = payload;
      break;
    case getPerformanceGraph.FULFILL:
      draft.loadingGraph = false;
      break;
    case getMeReports.FULFILL:
      draft.loadingReport = false;
      break;
    case setPeriodReport.toString():
      draft.periodReport = payload;
      break;
  }
}, initialState);

export default reducer;
