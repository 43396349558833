import { call, put, takeLatest, select, take } from 'redux-saga/effects';
import SDK from 'utils/sdk';
import { getLeadItem, getLeadsItems } from 'modules/leads/selectors';
import { getClientCurrent } from 'modules/clients/selectors';
import { getGroupCurrent } from 'modules/groups/selectors';
import { outboundCall } from 'modules/ringcentral/actions';
import { push } from 'connected-react-router';
import { setGroup } from 'modules/groups/actions';
import { getScript, getDefaultScript } from 'modules/scripts/actions';
import {
  listLeads,
  retrieveLead,
  createLead,
  updateLead,
  createNote,
  searchLead,
  reschedule,
  changeGroup,
  nextGroup,
  voicemail,
  startCall,
  nextLead,
} from './actions';

/** LEADS SAGAS */

export function* listLeadsSaga({ payload }) {
  while (true) {
    try {
      yield take(setGroup.toString());
      yield put(listLeads.request());
      const params = {
        groupId: yield select(getGroupCurrent),
        clientId: payload.id,
      };
      const api = yield call([SDK, 'getApi']);
      const { body } = yield call([api.leads, 'getLeads'], params);
      yield put(listLeads.success(body));
    } catch (error) {
      yield put(listLeads.failure(error));
    } finally {
      yield put(listLeads.fulfill());
    }
  }
}

export function* retrieveLeadSaga({ payload: { id, leadId } }) {
  try {
    yield put(retrieveLead.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.leads, 'getLead'], { id: leadId });
    yield put(retrieveLead.success(body));
    yield put(getScript({ id, lead: body }));
    yield put(getDefaultScript({ id, lead: body }));
  } catch (error) {
    yield put(retrieveLead.failure(error));
  } finally {
    yield put(retrieveLead.fulfill());
  }
}

export function* createLeadSaga({ payload: { values: requestBody } }) {
  try {
    yield put(createLead.request());
    const client = yield select(getClientCurrent);
    Object.assign(requestBody, {
      client: client.id,
      priority: 'High',
      group: client.groupId,
      subgroup: client.subgroupsId[0].id,
    });
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.leads, 'createLead'], {}, { requestBody });
    yield put(createLead.success(body));
  } catch (error) {
    yield put(createLead.failure(error.messages));
  } finally {
    yield put(createLead.fulfill());
  }
}

export function* updateLeadSaga({ payload: { values: requestBody } }) {
  try {
    yield put(updateLead.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api.leads, 'updateLead'],
      { id: requestBody.id },
      { requestBody }
    );
    yield put(updateLead.success(body));
  } catch (error) {
    yield put(updateLead.failure(error.messages));
  } finally {
    yield put(updateLead.fulfill());
  }
}

export function* createNoteSaga({ payload: content }) {
  try {
    yield put(createNote.request());
    const { id } = yield select(getLeadItem);
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api.leads, 'createNote'],
      { id },
      { requestBody: { content } }
    );
    yield put(createNote.success(body));
  } catch (error) {
    yield put(createNote.failure(error.messages));
  } finally {
    yield put(createNote.fulfill());
  }
}

export function* searchLeadSaga({ payload: value }) {
  try {
    yield put(searchLead.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.leads, 'searchLead'], { value });
    yield put(searchLead.success(body));
  } catch (error) {
    yield put(searchLead.failure(error));
  } finally {
    yield put(searchLead.fulfill());
  }
}

export function* rescheduleSaga({ payload }) {
  try {
    yield put(reschedule.request());
    const { id } = yield select(getLeadItem);
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api.leads, 'updateScheduled'],
      { id },
      {
        scheduled: payload,
      }
    );

    yield put(reschedule.success(body));
  } catch (error) {
    yield put(reschedule.failure(error.message));
  } finally {
    yield put(reschedule.fulfill());
  }
}

export function* changeGroupSaga({ payload }) {
  try {
    yield put(changeGroup.request());
    let group = parseInt(payload, 10);
    if (payload.values) {
      group = parseInt(payload.values.group, 10);
    }
    const { id } = yield select(getLeadItem);
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api.leads, 'changeGroup'],
      { id },
      { requestBody: { group } }
    );
    yield put(changeGroup.success(body));
  } catch (error) {
    yield put(changeGroup.failure(error.message));
  } finally {
    yield put(changeGroup.fulfill());
  }
}

export function* nextGroupSaga({ payload: { type, action } }) {
  try {
    yield put(nextGroup.request());
    const { id } = yield select(getLeadItem);
    if (action === 'voicemail') {
      const api = yield call([SDK, 'getApi']);
      const { body } = yield call([api.leads, 'nextGroup'], { id });
      yield put(voicemail());
      yield put(nextGroup.success(body));
    }
    if (type === 'next') {
      yield put(nextLead());
    }
  } catch (error) {
    yield put(nextGroup.failure(error.message));
  } finally {
    yield put(nextGroup.fulfill());
  }
}

export function* voicemailSaga() {
  try {
    yield put(voicemail.request());
    const { id } = yield select(getLeadItem);
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.leads, 'voicemail'], { id });
    yield put(voicemail.success(body));
  } catch (error) {
    yield put(voicemail.failure(error.message));
  } finally {
    yield put(voicemail.fulfill());
  }
}

// RINGCENTRAL SAGAS

export function* StartCallLeadSaga() {
  const client = yield select(getClientCurrent);
  const leads = yield select(getLeadsItems);
  if (leads.length > 0) {
    if (leads[0].shouldCall || leads[0].subgroupShouldCall) {
      const { id, cellphone } = leads[0];
      yield put(push(`/clients/${client.id}/lead/${id}`));
      yield put(outboundCall({ number: cellphone }));
    } else {
      yield put(nextLead.trigger());
    }
  } else {
    yield put(push(`/clients/${client.id}`));
  }
}

export function* callNextLeadSaga() {
  try {
    const client = yield select(getClientCurrent);
    const leads = yield select(getLeadsItems);
    if (leads.length > 0) {
      if (leads[0].shouldCall || leads[0].subgroupShouldCall) {
        const { id, cellphone } = leads[0];
        yield put(push(`/clients/${client.id}/lead/${id}`));
        yield put(outboundCall({ number: cellphone }));
      } else {
        yield put(nextLead.trigger());
      }
    } else {
      yield put(push(`/clients/${client.id}`));
    }
    yield put(nextLead.success());
  } catch (error) {
    yield put(nextLead.failure(error.message));
  } finally {
    yield put(nextLead.fulfill());
  }
}

export default function* leadsWatcher() {
  yield takeLatest(listLeads.TRIGGER, listLeadsSaga);
  yield takeLatest(retrieveLead.TRIGGER, retrieveLeadSaga);
  yield takeLatest(createLead.TRIGGER, createLeadSaga);
  yield takeLatest(createNote.TRIGGER, createNoteSaga);
  yield takeLatest(updateLead.TRIGGER, updateLeadSaga);
  yield takeLatest(searchLead.TRIGGER, searchLeadSaga);
  yield takeLatest(reschedule.TRIGGER, rescheduleSaga);
  yield takeLatest(changeGroup.TRIGGER, changeGroupSaga);
  yield takeLatest(nextGroup.TRIGGER, nextGroupSaga);
  yield takeLatest(voicemail.TRIGGER, voicemailSaga);
  yield takeLatest(startCall.toString(), StartCallLeadSaga);
  yield takeLatest(nextLead.TRIGGER, callNextLeadSaga);
}
