import { call, put, takeLatest } from 'redux-saga/effects';
import SDK from 'utils/sdk';
import { getPerformanceGraph, getMeReports, setPeriodReport } from './actions';

export function* getPerformanceGraphSaga() {
  try {
    yield put(getPerformanceGraph.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.reports, 'getStatisticsGraph']);
    yield put(getPerformanceGraph.success(body));
  } catch (error) {
    yield put(getPerformanceGraph.failure(error.message));
  } finally {
    yield put(getPerformanceGraph.fulfill());
  }
}

export function* getMeReportsSaga({ payload: { id, range = ',' } }) {
  try {
    yield put(getMeReports.request());
    yield put(setPeriodReport(range));
    const splitedRange = range.split(',');
    const params = {
      clientId: id,
      from: splitedRange && splitedRange[0],
      to: splitedRange && splitedRange[1],
    };
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.reports, 'getStatistics'], params);
    yield put(getMeReports.success(body));
  } catch (error) {
    yield put(getMeReports.failure(error.message));
  } finally {
    yield put(getMeReports.fulfill());
  }
}

export default function* clientsWatcher() {
  yield takeLatest(getPerformanceGraph.TRIGGER, getPerformanceGraphSaga);
  yield takeLatest(getMeReports.TRIGGER, getMeReportsSaga);
}
